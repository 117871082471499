import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { darul_sehat_api } from "../../datatablesource";

const initialState = {
  orders: [],
  medicines: [],
  isLoading: true,
  loading: false,
  show: "",
};

// Async thunk function to fetch pharmacy orders
export const fetchPharmacyOrders = createAsyncThunk(
  "pharmacy/fetchOrders",
  async ({ config }) => {
    try {
      const response = await axios.get(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/pharmacy-orders",
        config
      );
      return response.data;
    } catch (error) {
      console.log(error.response.data);
    }
  }
);

export const fetchMedicines = createAsyncThunk(
  "pharmacy/fetchMedicines",
  async ({ config }) => {
    try {
      const response = await axios.get(
        `${darul_sehat_api}items_rate_balance`,
        config
      );

      const responseData = response.data.itemsrates.map((row) => ({
        value: row.rn,
        label: row.sub_idesc,
        unit_price: row.unit_price,
        main_icode: row.main_icode,
        group_icode: row.group_icode,
        sub_icode: row.sub_icode,
        group_idesc: row.group_idesc,
        // generic_code: row.generic_code,
        generic_desc: row.generic_desc,
        pack_nos: row.pack_nos,
        balance: row.balance,
        prescription: row.prescription,
      }));

      console.log(responseData[0]);

      return responseData;
    } catch (error) {
      console.log(error.response.data);
    }
  }
);

const pharmacySlice = createSlice({
  name: "pharmacy",
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPharmacyOrders.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(fetchPharmacyOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orders = action.payload;
      })
      .addCase(fetchPharmacyOrders.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchMedicines.pending, (state) => {
        state.loading = true;
        state.show = "";
      })
      .addCase(fetchMedicines.fulfilled, (state, action) => {
        state.loading = false;
        state.medicines = action.payload;
        state.show = "Done";
      })
      .addCase(fetchMedicines.rejected, (state, action) => {
        state.loading = false;
        state.show = "Error";
      });
  },
});

export default pharmacySlice.reducer;

export const { setOrders } = pharmacySlice.actions;
