import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { darul_sehat_api } from "../../datatablesource";

// Async thunk function to fetch booking data
export const fetchBookingData = createAsyncThunk(
  "booking/fetchData",
  async () => {
    try {
      const response = await axios.get(
        `${darul_sehat_api}Appointmentdata`
      );
      return response.data.Appointmentdatas;
    } catch (error) {
      console.log(error.response.data);
    }
  }
);

// Create a slice for booking data state
const bookingSlice = createSlice({
  name: "booking",
  initialState: {
    bookingData: [],
    isLoading: true,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBookingData.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchBookingData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bookingData = action.payload;
      })
      .addCase(fetchBookingData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default bookingSlice.reducer;
