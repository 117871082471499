import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { darul_sehat_api } from "../../datatablesource";

export const fetchLabOrders = createAsyncThunk(
  "labOrders/fetchOrders",
  async ({ config }) => {
    try {
      const response = await axios.get(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/lab-orders",
        config
      );
      return response.data;
    } catch (error) {
      console.log(error.response.data);
    }
  }
);
export const fetchLabTests = createAsyncThunk(
  "labOrders/fetchTests",
  async ({ config }) => {
    try {
      const response = await axios.get(`${darul_sehat_api}lab_data`);

      return response.data.lab_datas;
    } catch (error) {
      console.log(error.response.data);
    }
  }
);

// Create a slice for lab orders state
const labOrdersSlice = createSlice({
  name: "labOrders",
  initialState: {
    orders: [],
    loading: true,
    tests: [],
    isLoading: true,
    error: null,
  },
  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLabOrders.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchLabOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.orders = action.payload;
      })
      .addCase(fetchLabOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchLabTests.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLabTests.fulfilled, (state, action) => {
        state.loading = false;
        state.tests = action.payload;
      })
      .addCase(fetchLabTests.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default labOrdersSlice.reducer;

export const { setOrders } = labOrdersSlice.actions;
