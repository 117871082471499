import "./widget.scss";
import PersonIcon from "@mui/icons-material/Person";
import MedicationIcon from "@mui/icons-material/Medication";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useState, useEffect } from "react";
import axios from "axios";
import CountUp from "react-countup";
import { darul_sehat_api } from "../../datatablesource";

const Widget = ({ type }) => {
  const [totalUsers, setTotalUsers] = useState();
  const [totalDoctors, setTotalDoctors] = useState();
  const [totalBookings, setTotalBookings] = useState();
  const [totalLabOrders, setTotalabOrders] = useState();
  const [totaPharmacyOrders, setTotaPharmacyOrders] = useState();
  const [totalReviews, setTotalReviews] = useState();
  const [totalToday, setTotalToday] = useState();
  const [totalBlocked, setTotalBlocked] = useState();

  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  let data;
  useEffect(() => {
    axios
      .get(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/users",
        config
      )
      .then((response) => {
        setTotalUsers(response.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/users/allBlocked",
        config
      )
      .then((response) => {
        setTotalBlocked(response.data.length);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${darul_sehat_api}Physicianprofile`, config)
      .then((response) => {
        setTotalDoctors(response.data?.physicians?.length);
      })
      .catch((error) => {});
    axios
      .get(`${darul_sehat_api}Appointmentdata`, config)
      .then((response) => {
        setTotalBookings(response.data?.Appointmentdatas?.length);
      })
      .catch((error) => {});

    axios
      .get(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/lab-orders",
        config
      )
      .then((response) => {
        setTotalabOrders(response.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/pharmacy-orders",
        config
      )
      .then((response) => {
        setTotaPharmacyOrders(response.data.length);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  switch (type) {
    case "users":
      data = {
        title: "TOTAL USERS",
        isMoney: false,
        amount: <CountUp start={0} end={totalUsers} duration={3} />,

        // link: "See all users",
        icon: (
          <PersonIcon
            className="icon"
            style={{
              backgroundColor: "white",
              color: "black",
            }}
          />
        ),
      };
      break;
    case "doctors":
      data = {
        title: "TOTAL DOCTORS",
        isMoney: false,
        amount: <CountUp start={0} end={totalDoctors} duration={3} />,

        // link: "See all users",
        icon: (
          <PersonIcon
            className="icon"
            style={{
              backgroundColor: "white",
              color: "black",
            }}
          />
        ),
      };
      break;
    case "labOrders":
      data = {
        title: "TOTAL LAB ORDERS",
        isMoney: false,
        amount: <CountUp start={0} end={totalLabOrders} duration={3} />,

        // link: "View all orders",
        icon: (
          <MedicationIcon
            className="icon"
            style={{
              backgroundColor: "white",
              color: "black",
            }}
          />
        ),
      };
      break;
    case "bookings":
      data = {
        title: "TOTAL APPOINTMENTS",
        isMoney: false,
        amount: <CountUp start={0} end={totalBookings} duration={3} />,
        // link: "View all orders",
        icon: (
          <LocationCityIcon
            className="icon"
            style={{
              backgroundColor: "white",
              color: "black",
            }}
          />
        ),
      };
      break;
    case "pharmacyOrders":
      data = {
        title: "TOTAL PHARMACY ORDERS",
        isMoney: false,
        amount: <CountUp start={0} end={totaPharmacyOrders} duration={3} />,
        // link: "View all orders",
        icon: (
          <LocationCityIcon
            className="icon"
            style={{
              backgroundColor: "white",
              color: "black",
            }}
          />
        ),
      };
      break;

    case "blocked":
      data = {
        title: "TOTAL BLOCKED ACCOUNTS",
        isMoney: false,
        amount: <CountUp start={0} end={totalBlocked} duration={3} />,
        // link: "View all orders",
        icon: (
          <MedicationIcon
            className="icon"
            style={{
              backgroundColor: "white",
              color: "black",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">{data.icon}</div>
      <div className="right">
        <span className="title">{data.title}</span>
        <span className="counter">
          {data.isMoney && "$"} {data.amount}
        </span>
      </div>
    </div>
  );
};

export default Widget;
