import "../../style/datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { darul_sehat_api } from "../../datatablesource";

const DatatableDoctors = () => {
  const [doctors, setDoctors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${darul_sehat_api}Physicianprofile`)
      .then((response) => {
        if (response.data) {
          setDoctors(response.data.physicians);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, [doctors]);

  const actionColumn = [
    {
      field: "physician_code",
      headerName: "Physician Code",
      width: 100,
    },
    {
      field: "physician_name",
      headerName: "Physician Name",
      width: 200,
    },
    {
      field: "qualification",
      headerName: "QUALIFICATION",
      width: 200,
    },
    {
      field: "doctor_desc",
      headerName: "SPECIALIZATION",
      width: 200,
    },
    {
      field: "clinic_fee",
      headerName: "CLINIC_FEE",
      width: 100,
    },
    {
      field: "shift",
      headerName: "SHIFT",
      width: 100,
    },
    {
      field: "days",
      headerName: "DAYS",
      width: 200,
    },
    {
      field: "start_time",
      headerName: "TIME_IN",
      width: 150,
    },
    {
      field: "end_time",
      headerName: "TIME_OUT",
      width: 150,
    },
    {
      field: "end_time",
      headerName: "TIME_OUT",
      width: 150,
    },
    {
      field: "maximum_appointment",
      headerName: "Maximum Appointments",
      width: 250,
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">Doctors</div>

      {isLoading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={doctors}
          columns={actionColumn}
          getRowId={(row, index) => {
            return row.rn;
          }}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default DatatableDoctors;
