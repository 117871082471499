// symptoms
// column headings

export const darul_sehat_api = "https://119.63.129.51:8000/api/";

// users
// columns heading
export const userColumns = [
  { field: "userId", headerName: "ID", width: 100 },
  { field: "name", headerName: "User Name", width: 150 },
  { field: "phoneNo", headerName: "Phone No.", width: 150 },
  { field: "country", headerName: "Country", width: 150 },
  { field: "city", headerName: "City", width: 150 },
];
export const doctorColumns = [
  { field: "userId", headerName: "UserId", width: 100 },
  { field: "name", headerName: "Name", width: 120 },
  { field: "phoneNo", headerName: "Phone No.", width: 150 },
  { field: "address", headerName: "Residential Address", width: 150 },
  { field: "city", headerName: "City", width: 100 },
  { field: "country", headerName: "Country", width: 120 },
  { field: "workPlaceName", headerName: "Clinical Name", width: 150 },
];
