import "../../style/datatable.css";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { fetchBookingData } from "../../redux/bookingSlice/BookingSlice";

const DatatableBookings = () => {
  // const [booking, setbooking] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const { bookingData, isLoading, error } = useSelector(
    (state) => state.booking
  );

  useEffect(() => {
    dispatch(fetchBookingData());
  }, [dispatch]);

  // useEffect(() => {
  //   axios
  //     .get("http://119.63.129.51:8000/api/Appointmentdata")
  //     .then((response) => {
  //       setbooking(response.data.Appointmentdatas);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }, []);

  const actionColumn = [
    {
      field: "physician_code",
      headerName: "Physician Code",
      width: 150,
    },
    {
      field: "patient_name",
      headerName: "Patient Name",
      width: 150,
    },
    {
      field: "contact_no",
      headerName: "Contact No",
      width: 150,
    },
    {
      field: "shift",
      headerName: "Shift",
      width: 150,
    },
    {
      field: "appointment_time",
      headerName: "Booking time",
      width: 150,
    },
    {
      field: "booking_date",
      headerName: "Booking date",
      width: 150,
    },
    {
      field: "apt_nature",
      headerName: "Appointment Nature",
      width: 200,
    },
  ];
  return (
    <div className="datatable">
      <div className="datatableTitle">Appointments</div>

      {isLoading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
          className="datagrid"
          rows={bookingData}
          columns={actionColumn}
          getRowId={(row) => {
            return row.rn;
          }}
          pageSize={9}
          rowsPerPageOptions={[9]}
        />
      )}
    </div>
  );
};

export default DatatableBookings;
