
import "./home.scss";
import "../../style/datatable.css";
import "../../style/new.scss";
import Widget from "../../components/widget/Widget";
import axios from "axios";
import PopupAlert from "../../components/popupalert/popupAlert";
import { useEffect, useState } from "react";

const Home = () => {
  const [titlePatient, setTitlePatient] = useState("");
  const [descriptionPatient, setDescriptionPatient] = useState("");
  const [titleDoctor, setTitleDoctor] = useState("");
  const [descriptionDoctor, setDescriptionDoctor] = useState("");
  const [popUpShow, setPopupshow] = useState(false);
  const [popUpText, setPopupText] = useState("");
  // Lab orders
  const [labOrdersConfirmed, setLabOrdersConfirmed] = useState();
  const [labOrdersCompleted, setLabOrdersCompleted] = useState();
  const [labOrdersOrdered, setLabOrdersOrdered] = useState();
  const [labOrdersCancelled, setLabOrdersCancelled] = useState();
  const [labOrdersOnTheWay, setLabOrdersOnTheWay] = useState();
  const [labOrdersDelivered, setLabOrdersDelivered] = useState();
  // Pharmacy orders
  const [pharmacyOrdersConfirmed, setPharmacyOrdersConfirmed] = useState();
  const [pharmacyOrdersCompleted, setPharmacyOrdersCompleted] = useState();
  const [pharmacyOrdersPending, setPharmacyOrdersPending] = useState();
  const [pharmacyOrdersCancelled, setPharmacyOrdersCancelled] = useState();
  const [pharmacyOrdersOnTheWay, setPharmacyOrdersOnTheWay] = useState();
  const [pharmacyOrdersDelivered, setPharmacyOrdersDelivered] = useState();

  const role = localStorage.getItem("dsh_role");
  const token = localStorage.getItem("dsh_token");

  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  useEffect(() => {
    if (role === "lab") {
      axios
        .get(
          "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/lab-orders",
          config
        )
        .then((response) => {
          const order = response.data.filter(
            (labOrder) => labOrder.status === "new-order"
          );
          const process = response.data.filter(
            (labOrder) => labOrder.status === "in-process"
          );
          const ontheway = response.data.filter(
            (labOrder) => labOrder.status === "on the way"
          );
          const delivered = response.data.filter(
            (labOrder) => labOrder.status === "delivered"
          );
          const completed = response.data.filter(
            (labOrder) => labOrder.status === "completed"
          );
          const cancelled = response.data.filter(
            (labOrder) => labOrder.status === "cancelled"
          );
          setLabOrdersOnTheWay(ontheway.length);
          setLabOrdersDelivered(delivered.length);
          setLabOrdersOrdered(order.length);
          setLabOrdersConfirmed(process.length);
          setLabOrdersCompleted(completed.length);
          setLabOrdersCancelled(cancelled.length);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (role === "pharmacy") {
      axios
        .get(
          "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/pharmacy-orders",
          config
        )
        .then((response) => {
          const pending = response.data.filter(
            (pharmacyOrder) => pharmacyOrder.status === "pending"
          );
          const confirmed = response.data.filter(
            (pharmacyOrder) => pharmacyOrder.status === "confirmed"
          );
          const ontheway = response.data.filter(
            (labOrder) => labOrder.status === "on the way"
          );
          const delivered = response.data.filter(
            (labOrder) => labOrder.status === "delivered"
          );
          const completed = response.data.filter(
            (pharmacyOrder) => pharmacyOrder.status === "completed"
          );
          const cancelled = response.data.filter(
            (pharmacyOrder) => pharmacyOrder.status === "cancelled"
          );
          setPharmacyOrdersOnTheWay(ontheway.length);
          setPharmacyOrdersDelivered(delivered.length);
          setPharmacyOrdersPending(pending.length);
          setPharmacyOrdersCancelled(cancelled.length);
          setPharmacyOrdersConfirmed(confirmed.length);
          setPharmacyOrdersCompleted(completed.length);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [
    labOrdersCancelled,
    labOrdersCompleted,
    labOrdersConfirmed,
    labOrdersOrdered,
    labOrdersDelivered,
    labOrdersOnTheWay,
    pharmacyOrdersCompleted,
    pharmacyOrdersDelivered,
    pharmacyOrdersOnTheWay,
    pharmacyOrdersCancelled,
    pharmacyOrdersConfirmed,
    pharmacyOrdersPending,
  ]);

  const handleSubmitPatients = (e) => {
    e.preventDefault();

    const notification = {
      title: titlePatient,
      body: descriptionPatient,
    };

    axios
      .post(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/notification",
        { notification: notification },
        config
      )
      .then((response) => {
        setPopupshow(true);
        setPopupText("Notification has been sent to patients");
        setTitlePatient("");
        setDescriptionPatient("");
        setTimeout(() => {
          setPopupshow(false);
          // window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleSubmitDoctors = (e) => {
    e.preventDefault();
    const notification = {
      title: titleDoctor,
      description: descriptionDoctor,
    };

    axios
      .post(
        "https://darulsehatapi.dsh.boundlesstechnologies.net/api/v1/notification/doctors",
        notification,
        config
      )
      .then((response) => {
        console.log(response.data);
        setPopupshow(true);
        setPopupText("Notification has been sent to doctors");
        setTitleDoctor("");
        setDescriptionDoctor("");
        setTimeout(() => {
          setPopupshow(false);
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {" "}
      <div className="widgets">
        <Widget type="users" />
        <Widget type="doctors" />
        <Widget type="bookings" />
      </div>
      <div className="widgets">
        <Widget type="labOrders" />
        <Widget type="pharmacyOrders" />
        <Widget type="blocked" />
      </div>
      {popUpShow ? (
        <PopupAlert popUpText={popUpText} backgroundColor={"#8AFF8A"} />
      ) : (
        ""
      )}
      <div style={{ display: "flex", marginLeft: 20 }}>
        {/*For patients*/}
        <div style={{ width: "100%" }}>
          <div className="datatableTitle">Send Notifications to Patients</div>
          <form
            className="form-new"
            onSubmit={handleSubmitPatients}
            method="post"
            encType="multipart/form-data"
            action="/upload"
          >
            <div
              className="formInput"
              style={{
                flexDirection: "column",
                display: "flex",
                width: "80%",
                marginLeft: 20,
              }}
            >
              <label className="label-form">Notification Title</label>
              <input
                type="text"
                placeholder="New Medicine Added"
                // className="input-form"
                value={titlePatient}
                onChange={(e) => {
                  setTitlePatient(e.target.value);
                }}
                required
              />
              <label className="label-form">Notification Message</label>
              <textarea
                className="input-form"
                rows="6"
                value={descriptionPatient}
                onChange={(e) => setDescriptionPatient(e.target.value)}
                required
              />

              <button className="createButton">Send</button>
            </div>
          </form>
        </div>
        {/*For doctors*/}
        {/* <div style={{ width: "100%" }}>
            <div className="datatableTitle">Send Notifications to Doctors</div>
            <div className="bottom">
              <div className="right">
                <form
                  className="form-new"
                  onSubmit={handleSubmitDoctors}
                  method="post"
                  encType="multipart/form-data"
                  action="/upload"
                >
                  <div
                    className="formInput"
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      width: "80%",
                      marginLeft: 20,
                    }}
                  >
                    <label className="label-form">Notification Title</label>
                    <input
                      type="text"
                      placeholder="New Medicine Added"
                      className="input-form"
                      value={titleDoctor}
                      onChange={(e) => {
                        setTitleDoctor(e.target.value);
                      }}
                      required
                    />
                    <label className="label-form">Notification Message</label>
                    <textarea
                      className="input-form"
                      rows="6"
                      value={descriptionDoctor}
                      onChange={(e) => setDescriptionDoctor(e.target.value)}
                      required
                    />

                    <button className="createButton">Send</button>
                  </div>
                </form>
              </div> 
            </div>
          </div>
              */}
      </div>
    </>
  );
};

export default Home;
